import React from 'react';
import { Image, Row } from 'react-bootstrap';

const HeaderLogo = ({ title, logo, logoSize }) => (
    <div className="mb-5">
        <div className="d-flex justify-content-center">
            <Image src={logo} style={{ width: logoSize, height: logoSize }} />
        </div>
        <div className="text-center p-3 mt-3">
            <h2>
                {title}
            </h2>
        </div>
    </div>
);

export default HeaderLogo;
